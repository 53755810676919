import Image from 'next/image';
import cx from 'classnames';
import { PlayIconM } from '@carvertical/icons/react';
import styles from './VideoThumbnail.module.scss';

type VideoThumbnailProps = {
  src: string;
  play: () => void;
  className?: string;
};

const VideoThumbnail = ({ className, play, src }: VideoThumbnailProps) => (
  <button
    type="button"
    className={cx(styles.root, className)}
    onClick={play}
    aria-label="Play video"
  >
    <Image src={src} fill alt="" className={styles.thumbnailImage} />

    <div className={styles.playIconWrapper}>
      <PlayIconM className="translate-x-0.25" />
    </div>
  </button>
);

export { VideoThumbnail };
export type { VideoThumbnailProps };
